require('intersection-observer');

const options = {
    root: null,
    rootMargin: "0px 0px -90% 0px",
    threshold: 0 // 閾値は0
};

const observer = new IntersectionObserver(doWhenIntersect, options);

const boxes = document.querySelectorAll("#contents");
//IE11対策
const boxesArr = Array.prototype.slice.call(boxes);
boxesArr.forEach(box => {
    observer.observe(box);
});

function doWhenIntersect(entries) {
    //IE11対策
    const entriesArr = Array.prototype.slice.call(entries);
    entriesArr.forEach(entry => {
        if (entry.isIntersecting) {
            document.getElementById('header').classList.add("inview");
        }else{
            document.getElementById('header').classList.remove("inview");
        }
    });
}
